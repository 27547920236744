import { Routes, Route } from 'react-router-dom'
import { useAuthContext } from './AuthContext'
import { Admins } from './pages/Admins'
import { Ads } from './pages/Ads'
import { Copy } from './pages/Copy'
import { Login } from './pages/Login'
import { ReportedUsers } from './pages/ReportedUsers'
import ProtectedRoute, { ProtectedRouteProps } from './ProtectedRoute'

export const Router = () => {
  const auth = useAuthContext()
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: auth.loggedIn,
    authenticationPath: '/login',
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Ads />} />
        }
      />
      <Route
        path="/copy"
        element={
          <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Copy />} />
        }
      />
      <Route
        path="/admins"
        element={
          <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Admins />} />
        }
      />
      <Route
        path="/reported-users"
        element={
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            outlet={<ReportedUsers />}
          />
        }
      />
    </Routes>
  )
}
