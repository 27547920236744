import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { AdCard } from '../components/AdCard'
import { AdModal } from '../components/AdModal'
import * as S from '../ui'

export type Ad = {
  adId: string
  address: string
  companyName: string
  url: string
  companyLogo: {
    url: string
  }
  thumbnail: {
    url: string
  }
}

export type Image = {
  uri: string
  type: string
  name: string
}

export const Ads: React.FC = () => {
  const [ads, setAds] = useState<Ad[]>([])
  const [edit, setEdit] = useState(false)
  const [editAd, setEditAd] = useState<Ad | undefined>(undefined)
  const token = localStorage.getItem('token')

  useEffect(() => {
    getAds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAds = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/ads`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setAds(res.data))
      .catch((err) => console.error('err', err))
  }

  const addAd = (ad: Ad, thumbnail?: File, logo?: File) => {
    const formData = new FormData()

    formData.append(
      'body',
      JSON.stringify({
        companyName: ad.companyName,
        url: ad.url,
        address: ad.address,
      })
    )
    thumbnail && formData.set('thumbnail', thumbnail)
    logo && formData.append('companyLogo', logo)

    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/ads`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => exitUpdate(res))
      .catch((err) => console.error('err', err))
  }

  const updateAd = (ad: Ad, thumbnail?: File, logo?: File) => {
    const formData = new FormData()

    formData.append(
      'body',
      JSON.stringify({
        companyName: ad.companyName,
        url: ad.url,
        address: ad.address,
      })
    )
    thumbnail && formData.set('thumbnail', thumbnail)
    logo && formData.append('companyLogo', logo)

    axios
      .patch(`${process.env.REACT_APP_API_URL}/v1/ads/${ad.adId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => exitUpdate(res))
      .catch((err) => console.error('err', err))
  }

  const deleteAd = (adId: string) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v1/ads/${adId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => exitUpdate())
      .catch((err) => console.error('err', err))
  }

  const exitEdit = () => {
    setEdit(false)
    setEditAd(undefined)
  }

  const exitUpdate = (res?: any) => {
    getAds()
    exitEdit()
  }

  return (
    <S.Wrapper>
      <S.ContentContainer>
        <S.Margin spacing="32px" bottom="64px">
          <S.Flex align="center" justify="space-between">
            <S.H1>Ads.</S.H1>
            <S.Button label="New ad" onClick={() => setEdit(true)} />
          </S.Flex>
          <S.Margin vertical="32px" />
          <AdsWrapper>
            {ads.map((ad) => {
              return (
                <AdCard
                  ad={ad}
                  onClick={() => {
                    setEdit(true)
                    setEditAd(ad)
                  }}
                />
              )
            })}
          </AdsWrapper>
          <AddButton onClick={() => setEdit(true)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 12H23M12 1V23"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </AddButton>
        </S.Margin>
      </S.ContentContainer>
      {edit && (
        <AdModal
          ad={editAd}
          onClose={exitEdit}
          onDelete={(ad) => deleteAd(ad)}
          onSave={(ad: Ad, thumbnail?: File, logo?: File) => {
            return editAd
              ? updateAd(ad, thumbnail, logo)
              : addAd(ad, thumbnail, logo)
          }}
        />
      )}
    </S.Wrapper>
  )
}

const AdsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 21px;
`

const AddButton = styled.button`
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: min-content;
  width: min-content;
  padding: 15px;
  position: fixed;
  right: 20px;
  bottom: 30px;
  background-color: #ff7a00;
`
