import React from 'react'
import { styled } from 'styled-components'
import * as S from './'

const Button = styled.button<{ align?: string }>`
  padding: ${(props) => (props.align ? '5px 0' : '5px')};
  align-self: ${(props) => props.align ?? 'center'};
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;
`

const Label = styled.p<{
  color?: string
  small?: boolean
  large?: boolean
  weight?: number
  underline?: boolean
}>`
  font-family: 'Roboto';
  color: ${(props) => props.color ?? '#ff7a00'};
  font-style: normal;
  font-weight: ${(props) => props.weight ?? 700};
  font-size: ${(props) =>
    props.small ? '14px' : props.large ? '18px' : '16px'};
  line-height: 19px;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
`

type Props = {
  label: string
  color?: string
  align?: string
  small?: boolean
  large?: boolean
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
  onClick?: () => void
  underline?: boolean
}

export const TextButton: React.FC<Props> = (props) => {
  return (
    <Button onClick={props.onClick} align={props.align}>
      <S.Flex dir="row" align="center" justify="center">
        <Label
          color={props.color}
          small={props.small}
          large={props.large}
          weight={props.weight}
          underline={props.underline}
        >
          {props.label}
        </Label>
      </S.Flex>
    </Button>
  )
}
