import styled from 'styled-components'

type FlexType = {
  dir?: string
  align?: string
  justify?: string
  width?: string
}

type MarginType = {
  spacing?: string
  vertical?: string
  horizontal?: string
  top?: string
  right?: string
  bottom?: string
  left?: string
  width?: string
}

export const Flex = styled.div<FlexType>`
  display: flex;
  flex-direction: ${(props) => props.dir ?? 'row'};
  align-items: ${(props) => props.align ?? 'flex-start'};
  justify-content: ${(props) => props.justify ?? 'flex-start'};
  width: ${(props) => props.width ?? 'auto'};
  margin: 0;
  padding: 0;
  height: min-content;
`

export const Margin = styled.div<MarginType>`
  margin-top: ${(props) => props.top ?? props.spacing ?? props.vertical ?? 0};
  margin-right: ${(props) =>
    props.right ?? props.spacing ?? props.horizontal ?? 0};
  margin-bottom: ${(props) =>
    props.bottom ?? props.spacing ?? props.vertical ?? 0};
  margin-left: ${(props) =>
    props.left ?? props.spacing ?? props.horizontal ?? 0};
  width: ${(props) => props.width ?? 'auto'};
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding-top: 30px;
`

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  position: relative;
`

export const LoginContentContainer = styled.div`
  width: 500px;
  padding: 50px;
  border-radius: 20px;
  margin-top: 150px;
  max-width: 1440px;
  background-color: #a1a1a121;

  @media screen and (max-width: 775px) {
    width: calc(100% - 64px);
    padding: 24px;
    background-color: white;
    margin-top: 20%;
  }
`
