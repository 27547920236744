import { createContext, useContext, useEffect, useReducer, useRef } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from './pages/firebase'

function reducer(state: State, action: any) {
  switch (action.type) {
    case 'login': {
      return {
        token: action.token,
        loggedIn: true,
      }
    }
    case 'logout': {
      return {
        token: undefined,
        loggedIn: false,
      }
    }
  }
  throw Error('Unknown action: ' + action.type)
}

type State = {
  token?: string
  loggedIn: boolean
  logIn?: (token: string) => void
  logOut?: () => void
}

export const initialState: State = {
  token: undefined,
  loggedIn: false,
  logIn: (token: string) => {},
  logOut: () => {},
}

export const AuthContext = createContext(initialState)

export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    token: localStorage.getItem('token'),
    loggedIn: localStorage.getItem('token') ? true : false,
  })

  const logoutTimerRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken(true).then((idToken) => {
          logIn(idToken) // refreshes token in your context and local storage
        })
      } else {
        logOut()
      }
    })
    return () => unsubscribe() // cleanup listener on unmount
  }, [])

  useEffect(() => {
    const resetInactivityTimer = () => {
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current)
      }
      logoutTimerRef.current = setTimeout(logOut, 1800000) // 0.5 hour
    }
    // Set the inactivity timer if logged in
    if (state.loggedIn) {
      resetInactivityTimer()
    }

    // Event listeners to detect user activity
    const events = ['mousemove', 'keydown', 'mousedown']
    events.forEach((event) => {
      window.addEventListener(event, resetInactivityTimer)
    })

    return () => {
      // Cleanup on unmount or logout
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current)
      }
      events.forEach((event) => {
        window.removeEventListener(event, resetInactivityTimer)
      })
    }
  }, [state.loggedIn])

  function logIn(token: string) {
    localStorage.setItem('token', token)
    dispatch({ type: 'login', token: token })
  }

  function logOut() {
    localStorage.removeItem('token')
    dispatch({
      type: 'logout',
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logIn,
        logOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
