import styled from 'styled-components'

type Props = {
  width?: string
}

export const Input = styled.input<Props>`
  flex: 1;
  width: ${(props) => props.width ?? '100%'};
  height: 37px;
  max-width: calc(100% - 32px);
  font-size: 14px;
  padding: 0 16px;
  border-radius: 9999px;
  border: 1px solid #dbdbdb;
  margin-left: auto;
  &:focus {
    outline: 1px solid #ff7a00;
  }
`
