import React from 'react'
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../AuthContext'
import * as S from '../ui'
import { auth } from './firebase'

export const Login: React.FC = () => {
  const [info, setInfo] = useState({ email: '', password: '' })
  const [error, setError] = useState<string | undefined>(undefined)
  const authContext = useAuthContext()
  const navigate = useNavigate()

  const sendResetEmail = () => {
    sendPasswordResetEmail(auth, info.email)
      .then(() => {
        alert('Password reset email sent! Please check your email.')
      })
      .catch((error) => {
        let errorMessage

        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage = 'The email address is not valid.'
            break
          case 'auth/user-not-found':
            errorMessage = 'There is no user with this email.'
            break
          default:
            errorMessage = error.message
        }

        setError(errorMessage)
      })
  }

  const login = () => {
    signInWithEmailAndPassword(auth, info.email, info.password)
      .then((userCredential) => {
        userCredential.user &&
          userCredential.user
            .getIdToken(true)
            .then((idToken) => {
              authContext.logIn?.(idToken)
              navigate('/')
            })
            .catch((error) => {
              console.error('Error getting token: ', error)
            })
      })
      .catch((error) => {
        let errorMessage

        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage = 'The email address is not valid.'
            break
          case 'auth/user-disabled':
            errorMessage = 'This admin has been disabled.'
            break
          case 'auth/user-not-found':
            errorMessage = 'There is no admin with this email.'
            break
          case 'auth/wrong-password':
            errorMessage = 'Wrong password.'
            break
          default:
            errorMessage = error.message // For any other error code not handled above
        }

        setError(errorMessage)
      })
  }

  return (
    <S.Wrapper>
      <S.LoginContentContainer>
        <S.H1>Login.</S.H1>
        <S.Margin vertical="22px" />
        <S.P>Email</S.P>
        <S.Margin vertical="8px" />
        <S.Input
          placeholder="email"
          value={info.email}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setInfo({ ...info, email: e.currentTarget.value })
          }}
        />
        <S.Margin vertical="18px" />
        <S.P>Password</S.P>
        <S.Margin vertical="8px" />
        <S.Input
          type="password"
          placeholder="password"
          value={info.password}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setInfo({ ...info, password: e.currentTarget.value })
          }}
        />
        {error && (
          <>
            <S.Margin vertical="12px" />
            <S.P color="#ff7a00" weight={700}>
              {error}
            </S.P>
          </>
        )}
        <S.Margin vertical="22px" />
        <S.Button onClick={login} color="#ff7a00" label="Sign In" />
        <S.Margin vertical="10px" />
        <S.TextButton
          underline
          small
          weight={100}
          onClick={sendResetEmail}
          color="#333333"
          label="Forgot Password?"
        />
      </S.LoginContentContainer>
    </S.Wrapper>
  )
}
