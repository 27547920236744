import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Ad } from '../../pages/Ads'
import * as S from '../../ui'

type Props = {
  title?: string
  ad?: Ad
  onClose?: () => void
  hideCloseButton?: boolean
  primaryDisabled?: boolean
  bottomPrimaryLabel?: string
  bottomSecondaryLabel?: string
  handleBottomPrimary?: () => void
  handleBottomSecondary?: () => void
}

export const Modal: React.FC<PropsWithChildren<Props>> = (props) => {
  return (
    <Background onClick={props.onClose}>
      <ModalContainer
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation()
        }}
      >
        {!props.hideCloseButton && (
          <CloseButton onClick={props.onClose}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.778174 16.3345L8.55635 8.55634M16.3345 0.77816L8.55635 8.55634M8.55635 8.55634L0.778174 0.77816M8.55635 8.55634L16.3345 16.3345"
                stroke="#7A7A7A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </CloseButton>
        )}
        <S.H1>{props.title}</S.H1>

        <S.Margin vertical="8px" />
        <ContentContainer>{props.children}</ContentContainer>
        <S.Margin vertical="16px" />
        <S.Flex justify="end">
          {props.bottomSecondaryLabel && (
            <>
              <S.TextButton
                label={props.bottomSecondaryLabel}
                onClick={() => props.handleBottomSecondary?.()}
              />
              <S.Margin horizontal="9px" />
            </>
          )}
          {props.bottomPrimaryLabel && (
            <S.Button
              disabled={props.primaryDisabled}
              label={props.bottomPrimaryLabel}
              onClick={() => props.handleBottomPrimary?.()}
            />
          )}
        </S.Flex>
      </ModalContainer>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
  z-index: 10;
  top: -80px;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  min-width: 100vw;
  background: rgba(0, 0, 0, 0.424);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000;
`

const ModalContainer = styled.div`
  background-color: white;
  width: calc(100% - 64px);
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 20px;
  padding: 24px;
  max-width: 600px;
  max-height: calc(95vh - 40px);
`

const CloseButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  position: absolute;
  top: 44px;
  right: 16px;
  z-index: 10;
`

const ContentContainer = styled.div`
  @media screen and (min-width: 500px) {
    padding: 0px 16px;
  }
`
