import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { CopySection } from '../components/CopySection'
import * as S from '../ui'

type ScreenKey = 'communicate' | 'create' | 'plan' | 'share'

export type CopyType = {
  key: ScreenKey
  image_url: string
  title: string
  description: string
}

export const Copy: React.FC = () => {
  const [copy, setCopy] = useState<CopyType[]>([])
  const [success, setSuccess] = useState(false)
  const token = localStorage.getItem('token')

  useEffect(() => {
    getCopy()
  }, [])

  const getCopy = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/translations/app/en`)
      .then((res) => {
        let newCopy: CopyType[] = []
        const items = res.data.login.introduction

        for (const item of Object.keys(items)) {
          const copyItem = items[item]
          newCopy.push({
            key: item as ScreenKey,
            title: copyItem.title,
            description: copyItem.description,
            image_url: copyItem.image_url,
          })
        }
        setCopy(newCopy)
      })
  }

  const updateCopy = (updated: CopyType) => {
    const updatedCopyList = copy.map((c) => {
      if (c.key === updated.key) {
        return updated
      }
      return c
    })
    setCopy(updatedCopyList)

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/translations/app/en`,
        {
          translations: [
            {
              key: `login.introduction.${updated.key}.title`,
              value: updated.title,
            },
            {
              key: `login.introduction.${updated.key}.description`,
              value: updated.description,
            },
            {
              key: `login.introduction.${updated.key}.image_url`,
              value: updated.image_url,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setSuccess(true)
        getCopy()
        setTimeout(() => {
          setSuccess(false)
        }, 2000)
      })
      .catch((err) => console.error('err: ', err))
  }

  return (
    <S.Wrapper>
      <S.ContentContainer>
        <S.Margin spacing="32px">
          <S.H1>Copy.</S.H1>
          <S.Margin vertical="16px" />
          <CopyContainer>
            {copy.map((c, i) => {
              return (
                <CopySection
                  key={i}
                  copy={c}
                  setCopy={(updated: CopyType) => updateCopy(updated)}
                />
              )
            })}
            <Success className={success ? 'active' : ''}>
              <svg
                width="107"
                height="81"
                viewBox="0 0 107 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 38.5L42.5 75.5L101.5 5.5"
                  stroke="white"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Success>
          </CopyContainer>
        </S.Margin>
      </S.ContentContainer>
    </S.Wrapper>
  )
}

const CopyContainer = styled.div`
  max-width: 700px;
  margin: auto;
`

const Success = styled.div`
  opacity: 0;
  transition: all 0.25s ease-in-out;
  background-color: #333333b8;
  height: 200px;
  width: 200px;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    opacity: 1;
  }
`
