import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Header, LoggedOutHeader } from './components/Header'

import { AuthProvider, useAuthContext } from './AuthContext'
import { Router } from './Router'

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ConditionalHeader />
        <Router />
      </BrowserRouter>
    </AuthProvider>
  )
}

function ConditionalHeader() {
  const { loggedIn } = useAuthContext()

  return loggedIn ? <Header /> : <LoggedOutHeader />
}
