import styled from 'styled-components'

type Props = {
  color?: string
  align?: 'left' | 'center' | 'right'
  large?: boolean
  decoration?: 'none' | 'underline' | 'line-through' | 'underline line-through'
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
}

export const H1 = styled.h1<Props>`
  font-family: 'Roboto';
  color: ${(props) => props.color ?? '#333333'};
  text-align: ${(props) => props.align ?? 'left'};
  font-size: 48px;
  margin: 0;
`

export const H2 = styled.h2<Props>`
  font-family: 'Roboto';
  color: ${(props) => props.color ?? '#333333'};
  text-align: ${(props) => props.align ?? 'left'};
  font-size: 28px;
  margin: 0;
`

export const H3 = styled.h3<Props>`
  font-family: 'Roboto';
  color: ${(props) => props.color ?? '#333333'};
  text-align: ${(props) => props.align ?? 'left'};
  font-size: 18px;
  margin: 0;
`

export const P = styled.p<Props>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: ${(props) => props.weight ?? 400};
  text-align: ${(props) => props.align ?? 'left'};
  font-size: ${(props) => (props.large ? '18px' : '14px')};
  line-height: 17px;
  text-decoration: none;
  color: ${(props) => props.color ?? '#333333'};
  margin: 0;
  word-break: break-all;
`
