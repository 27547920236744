import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import * as S from '../ui'
import { Modal } from '../components/Modal'
import { Input } from '../ui'
import { auth } from './firebase'
import { Loading } from '../ui/Loading'

type User = {
  name: string
  email: string
  firebaseUID?: string
  userId: string
}

const emptyUser = {
  name: '',
  email: '',
  userId: '',
}

export const Admins: React.FC = () => {
  const token = localStorage.getItem('token')
  const [add, setAdd] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [newUser, setNewUser] = useState<User>(emptyUser)
  const [remove, setRemove] = useState<User | undefined>()
  const [users, setUsers] = useState<User[]>([])
  const [currentUserEmail, setCurrentUserEmail] = useState<string | null>('')

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserEmail(user.email)
      } else {
        // No user is signed in.
        setCurrentUserEmail('')
      }
    })
  }, [])

  useEffect(() => {
    getAdmins()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedUsers = useMemo(() => {
    return [...users].sort((a, b) =>
      a.email === currentUserEmail ? -1 : b.email === currentUserEmail ? 1 : 0
    )
  }, [users, currentUserEmail])

  const getAdmins = () => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/admin`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setUsers(res.data)
        setLoading(false)
      })
      .catch((err) => console.error('err', err))
  }

  const removeUser = () => {
    const newUsers = users.filter((user) => {
      return user.userId !== remove?.userId
    })
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v1/admin/${remove?.userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.error('err', err))
    setUsers(newUsers)
    setRemove(undefined)
  }

  const addUser = () => {
    const user = {
      name: newUser.name,
      email: newUser.email,
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/admin`, user, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        getAdmins()
        setAdd(false)
        setNewUser(emptyUser)
      })
      .catch((error) =>
        setError(error.response.data.message || 'An unknown error occurred.')
      )
  }

  const updateNewUser = (field: keyof User, value: string) => {
    setNewUser({ ...newUser, [field]: value })
    setError('')
  }

  return (
    <S.Wrapper>
      <S.ContentContainer>
        {loading && <Loading />}
        <S.Margin spacing="32px">
          <S.Flex align="center" justify="space-between">
            <S.H1>Admins.</S.H1>
            <S.Button label="New admin" onClick={() => setAdd(true)} />
          </S.Flex>
        </S.Margin>
        <AdminsWrapper>
          {sortedUsers.map((user) => {
            return (
              <AdminContainer>
                <S.Flex justify="space-between" align="center" width="100%">
                  <S.Flex dir="column" justify="center">
                    <S.P weight={700}>{user.name}</S.P>
                    <S.P>{user.email}</S.P>
                  </S.Flex>
                  {currentUserEmail !== user.email && (
                    <S.TextButton
                      label="Delete"
                      onClick={() => {
                        setRemove(user)
                      }}
                    />
                  )}
                </S.Flex>
              </AdminContainer>
            )
          })}
        </AdminsWrapper>
        {add && (
          <Modal title="Add." onClose={() => setAdd(false)}>
            <S.P>Name</S.P>
            <S.Margin vertical="8px" />
            <Input
              placeholder="Name"
              value={newUser.name}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                updateNewUser('name', e.currentTarget.value)
              }
            />
            <S.Margin vertical="12px" />
            <S.P>Email</S.P>
            <S.Margin vertical="8px" />
            <Input
              placeholder="Email"
              value={newUser.email}
              type="email"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                updateNewUser('email', e.currentTarget.value)
              }
            />
            <S.Margin vertical="24px" />
            <S.Flex justify="flex-end">
              <S.Margin horizontal="-16px">
                <S.Button
                  onClick={addUser}
                  label="Save"
                  disabled={!newUser.name.length || !newUser.email.length}
                />
              </S.Margin>
            </S.Flex>
            {error && (
              <>
                <S.Margin vertical="12px" />
                <S.Flex justify="flex-end">
                  <S.P color="#ff7a00">{error}</S.P>
                </S.Flex>
              </>
            )}
          </Modal>
        )}
        {remove && (
          <Modal title="Delete." onClose={() => setRemove(undefined)}>
            <S.Margin spacing="24px">
              <S.H3 align="center">
                Are you sure you want to delete {remove.email}?
              </S.H3>
              <S.Margin vertical="12px" />
              <S.P align="center">This action can not be undone.</S.P>
              <S.Margin vertical="24px" />
              <S.Margin horizontal="-16px">
                <S.Flex justify="flex-end">
                  <S.Button
                    onClick={() => setRemove(undefined)}
                    label="Cancel"
                    outlined
                  />
                  <S.Margin horizontal="8px" />
                  <S.Button onClick={removeUser} label="Delete" />
                </S.Flex>
              </S.Margin>
            </S.Margin>
          </Modal>
        )}
      </S.ContentContainer>
    </S.Wrapper>
  )
}

const AdminsWrapper = styled.div`
  max-width: 700px;
  margin: auto;
`

const AdminContainer = styled.div`
  filter: drop-shadow(0px 0px 15px rgba(128, 128, 128, 0.15));
  background-color: white;
  border-radius: 5px;
  padding: 8px 16px;
  height: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 25px;
`
