import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import { Loading } from '../ui/Loading'

type Report = {
  userId: string
  reportsId: string
  reports: {
    userId: string
    username: string
    name: string
    email: string
    phone: string
    occupation: string
    avatar: {
      url: string
    }
  }
  createdAt: Date
}

export const ReportedUsers: React.FC = () => {
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [reports, setReports] = useState<Report[]>([])

  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/user-reports`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setReports(res.data)
        setLoading(false)
      })
      .catch((err) => console.error('err', err))
  }, [token])

  return (
    <>
      <S.Wrapper>
        <S.ContentContainer>
          <S.Margin spacing="32px">
            <S.H1>Reported users.</S.H1>
            <S.Margin vertical="16px" />
            {loading ? (
              <Loading />
            ) : (
              reports.map((report) => {
                return (
                  <UserContainer>
                    <S.Margin vertical="12px" horizontal="24px">
                      <S.Flex
                        justify="space-between"
                        align="center"
                        width="100%"
                        style={{ overflow: 'scroll' }}
                      >
                        <S.Flex align="center">
                          <UserImageContainer>
                            <UserImage src={report?.reports?.avatar?.url} />
                          </UserImageContainer>
                          <S.Margin right="24px" />
                          <Section>
                            <S.Flex dir="column" justify="center">
                              <S.Flex>
                                <S.P>user id:</S.P>
                                <S.Margin right="4px" />
                                <S.P weight={700}>
                                  {report?.reports?.userId}
                                </S.P>
                              </S.Flex>

                              <S.Margin bottom="4px" />

                              <S.Flex>
                                <S.P>name:</S.P>
                                <S.Margin right="4px" />
                                <S.P weight={700}>{report?.reports?.name}</S.P>
                              </S.Flex>
                            </S.Flex>
                            <S.Margin right="32px" />
                          </Section>

                          <Section>
                            <S.Flex dir="column" justify="center">
                              <S.Flex>
                                <S.P>phone:</S.P>
                                <S.Margin right="4px" />
                                <S.P weight={700}>{report?.reports?.phone}</S.P>
                              </S.Flex>

                              <S.Margin bottom="4px" />

                              <S.Flex>
                                <S.P>email:</S.P>
                                <S.Margin right="4px" />
                                <S.P weight={700}>{report?.reports?.email}</S.P>
                              </S.Flex>
                            </S.Flex>
                            <S.Margin right="32px" />
                          </Section>

                          <Section>
                            <S.Flex dir="column" justify="center">
                              <S.Flex>
                                <S.P>username:</S.P>
                                <S.Margin right="4px" />
                                <S.P weight={700}>
                                  {report?.reports?.username}
                                </S.P>
                              </S.Flex>

                              <S.Margin bottom="4px" />

                              <S.Flex>
                                <S.P>occupation:</S.P>
                                <S.Margin right="4px" />
                                <S.P weight={700}>
                                  {report?.reports?.occupation}
                                </S.P>
                              </S.Flex>
                            </S.Flex>
                            <S.Margin right="32px" />
                          </Section>
                        </S.Flex>

                        <Section>
                          <S.Flex align="center">
                            <VerticalLine />
                            <S.Margin right="32px" />

                            <S.Flex dir="column" justify="center">
                              <S.Flex>
                                <S.P>report id: </S.P>
                                <S.Margin right="4px" />
                                <S.P weight={700}> {report?.reportsId}</S.P>
                              </S.Flex>

                              <S.Margin bottom="4px" />

                              <S.Flex>
                                <S.P>reported by: </S.P>
                                <S.Margin right="4px" />
                                <S.P weight={700}> {report?.userId}</S.P>
                              </S.Flex>
                            </S.Flex>
                          </S.Flex>
                        </Section>
                      </S.Flex>
                    </S.Margin>
                  </UserContainer>
                )
              })
            )}
          </S.Margin>
        </S.ContentContainer>
      </S.Wrapper>
    </>
  )
}

const UserContainer = styled.div`
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  margin: 9px 0;
  width: 100%;
  background-color: white;
  filter: drop-shadow(0px 0px 15px rgba(128, 128, 128, 0.15));
`

const VerticalLine = styled.div`
  height: 60px;
  width: 1px;
  background-color: #67676798;
`

const Section = styled.div`
  min-width: 200px;
`

const UserImageContainer = styled.div`
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
`

const UserImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`
