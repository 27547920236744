import React from 'react'
import styled from 'styled-components'
import { Ad } from '../../pages/Ads'
import * as S from '../../ui'

type Props = {
  ad: Ad
  onClick: () => void
}

export const AdCard: React.FC<Props> = (props) => {
  return (
    <AdContainer onClick={props.onClick}>
      <AdTitle>
        <LogoContainer>
          {props.ad.companyLogo?.url && (
            <AdLogo src={props.ad.companyLogo?.url} />
          )}
        </LogoContainer>
        <S.Margin right="45px" />

        <S.Flex dir="column" width="100%">
          <S.Flex width="100%">
            <S.P weight={900}>{props.ad.companyName}</S.P>
            <S.Margin horizontal="auto" />
            <S.P align="right">Sponsored</S.P>
          </S.Flex>

          {props.ad.address && (
            <S.Flex dir="row" align="center">
              <svg
                width="10"
                height="14"
                viewBox="0 0 10 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.34532 8.07438L1.34566 8.07491C1.67199 8.59333 2.05176 9.07776 2.44741 9.58068L2.46545 9.60361C2.85123 10.094 3.25158 10.6028 3.60153 11.1529M1.34532 8.07438L3.26429 11.368M1.34532 8.07438C0.732417 7.1041 0.4 6.03645 0.4 4.9C0.4 2.41949 2.44897 0.4 5 0.4C7.55103 0.4 9.6 2.41949 9.6 4.9C9.6 6.03632 9.26768 7.10331 8.6486 8.07271L8.64858 8.0727L8.64658 8.0759C8.32042 8.59742 7.94071 9.0835 7.54545 9.58593C7.53322 9.60147 7.52097 9.61704 7.50871 9.63262C7.12898 10.1152 6.73587 10.6148 6.3917 11.1523L6.39158 11.1525C6.04043 11.7017 5.79527 12.2126 5.53031 12.781L5.52999 12.7817C5.49475 12.8577 5.4581 12.949 5.42509 13.0313C5.41188 13.0642 5.39925 13.0957 5.38753 13.1241C5.34145 13.2359 5.29781 13.3318 5.2493 13.4115C5.15487 13.5668 5.08408 13.6 5 13.6C4.9159 13.6 4.84489 13.5669 4.74918 13.4105C4.70002 13.3302 4.6557 13.234 4.60833 13.1214C4.59746 13.0955 4.5859 13.0675 4.57384 13.0382C4.53932 12.9543 4.50071 12.8605 4.46243 12.7807M1.34532 8.07438L4.1 12.95M3.60153 11.1529C3.60161 11.153 3.6017 11.1532 3.60179 11.1533L3.26429 11.368M3.60153 11.1529C3.60144 11.1528 3.60136 11.1526 3.60128 11.1525L3.26429 11.368M3.60153 11.1529C3.95097 11.6994 4.20244 12.2076 4.46243 12.7807M3.26429 11.368C3.6 11.893 3.84286 12.383 4.1 12.95M4.46243 12.7807C4.46171 12.7792 4.46099 12.7777 4.46027 12.7762L4.1 12.95M4.46243 12.7807C4.46305 12.7821 4.46367 12.7834 4.46429 12.7848L4.1 12.95M2.81429 5.075C2.81429 6.26949 3.80103 7.225 5 7.225C6.19897 7.225 7.18571 6.26949 7.18571 5.075C7.18571 3.88051 6.19897 2.925 5 2.925C3.80103 2.925 2.81429 3.88051 2.81429 5.075Z"
                  fill="white"
                  stroke="#333333"
                  stroke-width="0.8"
                />
              </svg>

              <S.Margin horizontal="6px" />
              <S.P>{props.ad.address}</S.P>
            </S.Flex>
          )}
        </S.Flex>
      </AdTitle>
      {props.ad.thumbnail?.url && <AdImage src={props.ad.thumbnail?.url} />}
    </AdContainer>
  )
}

const AdContainer = styled.div`
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  margin: 9px 0;
  height: 250px;
  width: 100%;
  background-color: #a1a1a121;
  max-width: 400px;
  margin: auto;
  filter: drop-shadow(0px 0px 15px rgba(128, 128, 128, 0.15));
`

const AdTitle = styled.div`
  padding: 15px 16px;
  display: flex;
  align-items: center;
  background-color: white;
`

const LogoContainer = styled.div`
  height: 30px;
  width: 30px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  justify-self: flex-start;
  position: absolute;
`

const AdLogo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
`

const AdImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`
