import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CopyType } from '../../pages/Copy'
import * as S from '../../ui'
import useRawImageUpload from '../../hooks/useRawImageUpload'

type Props = {
  copy: CopyType
  setCopy: (copy: CopyType) => void
}

export const CopySection: React.FC<Props> = (props) => {
  const [copy, setCopy] = useState<CopyType>({
    key: 'plan',
    image_url: '',
    title: '',
    description: '',
  })

  const { imageUrl, setSelectedImage: uploadImage } = useRawImageUpload()
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    setCopy(props.copy)
  }, [props.copy])

  useEffect(() => {
    if (imageUrl) {
      const updatedCopy = {
        ...copy,
        image_url: imageUrl,
      }
      setCopy(updatedCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl])

  useEffect(() => {
    if (
      props.copy.image_url !== copy.image_url ||
      props.copy.title !== copy.title ||
      props.copy.description !== copy.description
    ) {
      return setUpdated(true)
    }
    setUpdated(false)
  }, [props.copy, copy])

  const updateCopy = (field: keyof CopyType, value: string) => {
    setCopy({
      ...copy,
      [field]: value,
    })
  }

  const hiddenImageInput = React.useRef<HTMLInputElement>(null)

  const handleImageSelect = () => {
    hiddenImageInput?.current?.click()
  }

  const handleImageChange = (event: any) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      uploadImage(fileUploaded) // This will upload the image and update the imageUrl once done
    }
    updateCopy('image_url', copy.image_url)
  }

  return (
    <>
      <Container>
        <S.Margin>
          <ImageContainer
            onClick={() => {
              handleImageSelect()
            }}
          >
            {copy.image_url ? (
              <img src={copy.image_url} alt={`onboarding ${props.copy.key}`} />
            ) : (
              <img
                src="/placeholder.png"
                alt={`onboarding ${props.copy.key}`}
              />
            )}

            <Backdrop />
            <S.Button
              label="Edit"
              onClick={(e) => {
                e.stopPropagation()
                handleImageSelect()
              }}
            />
            <input
              type="file"
              ref={hiddenImageInput}
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
          </ImageContainer>
        </S.Margin>

        <div style={{ width: '100%' }}>
          <S.H2>
            {props.copy.key.charAt(0).toUpperCase() + props.copy.key.slice(1)}
          </S.H2>
          <S.Margin vertical="12px" />
          <S.P>Title</S.P>
          <S.Margin vertical="8px" />
          <S.Input
            value={copy.title}
            width="100%"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              updateCopy('title', e.currentTarget.value)
            }
          />
          <S.Margin vertical="16px" />
          <S.P>Text</S.P>
          <S.Margin vertical="8px" />
          <S.Input
            value={copy.description}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              updateCopy('description', e.currentTarget.value)
            }
          />
          <S.Margin vertical="16px" />
          <S.P>Image URL</S.P>
          <S.Margin vertical="8px" />
          <S.Input value={copy.image_url} readOnly />
          <ButtonContainer className={updated ? 'updated' : ''}>
            <S.Margin vertical="18px" />
            <S.Flex justify="end">
              <S.TextButton
                label="Cancel"
                onClick={() => {
                  setCopy(props.copy)
                }}
              />
              <S.Margin horizontal="8px" />
              <S.Button
                label="Save"
                onClick={() => {
                  props.setCopy(copy)
                }}
              />
            </S.Flex>
          </ButtonContainer>
        </div>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;

  @media screen and (max-width: 775px) {
    flex-direction: column;
  }
`

const Backdrop = styled.div`
  background-color: #ffffff9a;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.25s ease-in-out;
`

const ButtonContainer = styled.div`
  opacity: 0;
  transition: all 0.25s ease-in-out;

  &.updated {
    opacity: 1;
  }
`

const ImageContainer = styled.div`
  width: 263px;
  height: 400px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 16px;
  position: relative;
  cursor: pointer;
  margin-bottom: 46px;
  transition: all 0.25s ease-in-out;
  filter: drop-shadow(0px 0px 15px rgba(128, 128, 128, 0.3));

  button {
    opacity: 0;
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.25s ease-in-out;
  }

  &:hover {
    scale: 0.99;

    div {
      opacity: 1;
    }

    button {
      opacity: 1;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media screen and (max-width: 775px) {
    width: 100%;
    height: 120vw;
    margin-bottom: 24px;
  }
`
