import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button<{
  disabled?: boolean
  selected?: boolean
  fullWidth?: boolean
  outlined?: boolean
  width?: string
  height?: string
  maxWidth?: string
  maxHeight?: string
  minWidth?: string
  minHeight?: string
  color?: string
}>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: ${(props) => (props.fullWidth ? 'auto' : 'center')};
  max-height: ${(props) => props.maxHeight ?? 'auto'};
  max-width: ${(props) => props.maxWidth ?? 'auto'};
  min-height: ${(props) => props.minHeight ?? 'auto'};
  min-width: ${(props) => props.minWidth ?? 'auto'};
  height: ${(props) => props.height ?? '37px'};
  width: ${(props) => (props.fullWidth ? '100%' : props.width ?? 'auto')};
  border: ${(props) =>
    props.selected ? 'none' : props.outlined ? `1px solid #333333` : 'none'};
  padding: ${(props) => (props.outlined ? '7px 15px' : '8px 16px')};
  background-color: ${(props) =>
    props.color ??
    (props.disabled
      ? '#C1C1C1'
      : props.selected
      ? '#FF7A00'
      : props.outlined
      ? 'transparent'
      : '#333333')};
`

const Label = styled.p<{
  disabled?: boolean
  selected?: boolean
  outlined?: boolean
}>`
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  line-height: 19px;
  color: ${(props) =>
    props.disabled
      ? '#9B9B9B'
      : props.selected
      ? '#FFFFFF'
      : props.outlined
      ? '#333333'
      : '#FFFFFF'};
`

type Props = {
  label: string
  disabled?: boolean
  selected?: boolean
  fullWidth?: boolean
  outlined?: boolean
  width?: string
  height?: string
  maxWidth?: string
  maxHeight?: string
  minWidth?: string
  minHeight?: string
  left?: React.ReactNode
  right?: React.ReactNode
  color?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const Button: React.FC<Props> = (props) => {
  return (
    <StyledButton
      height={props.height}
      width={props.width}
      maxWidth={props.maxWidth}
      maxHeight={props.maxHeight}
      minWidth={props.minWidth}
      minHeight={props.minHeight}
      disabled={props.disabled}
      selected={props.selected}
      fullWidth={props.fullWidth}
      outlined={props.outlined}
      onClick={props.onClick}
      color={props.color}
    >
      {props.left}

      <Label
        disabled={props.disabled}
        selected={props.selected}
        outlined={props.outlined}
      >
        {props.label}
      </Label>

      {props.right}
    </StyledButton>
  )
}
