import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const StyledButton = styled.button<{
  padding?: string
  color?: string
  border?: boolean
}>`
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: min-content;
  width: min-content;
  padding: ${(props) => props.padding ?? '15px'};
  background-color: ${(props) => props.color ?? '#333333'};
  border: ${(props) => (props.border ? `1px solid #333333` : 'none')};
  position: relative;
`

type Props = {
  padding?: string
  color?: string
  onClick?: () => void
}

export const RoundButton: React.FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledButton padding={props.padding} onClick={props.onClick}>
      {props.children}
    </StyledButton>
  )
}
