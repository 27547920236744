import axios from 'axios'

export const uploadRawImage = async (image: File): Promise<any> => {
  const formData = new FormData()
  formData.append('file', image)
  const token = localStorage.getItem('token')

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/files/uploadRaw`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response // Adjust based on your backend's response structure
  } catch (error) {
    console.error('Failed to upload image:', error)
    return null
  }
}
