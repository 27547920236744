import React from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { Ad } from '../../pages/Ads'
import * as S from '../../ui'
import { AdCard } from '../AdCard'
import { Modal } from '../Modal'

type Props = {
  ad?: Ad
  onClose?: () => void
  onSave?: (ad: Ad, thumbnail?: File, logo?: File) => void
  onDelete?: (adId: string) => void
}

const emptyAd = {
  adId: '',
  address: '',
  companyName: '',
  url: '',
  companyLogo: {
    url: '',
  },
  thumbnail: {
    url: '',
  },
}

export const AdModal: React.FC<Props> = (props) => {
  const [ad, setAd] = useState<Ad>(emptyAd)
  const [update, setUpdate] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [selectedLogo, setSelectedLogo] = useState<File>()
  const [selectedImage, setSelectedImage] = useState<File>()

  useEffect(() => {
    if (props.ad) {
      setAd(props.ad)
      setUpdate(true)
    }
  }, [props.ad])

  const updateAd = useCallback((field: keyof Ad, value?: string) => {
    setAd((prevAd) => {
      if (field === 'companyLogo' || field === 'thumbnail') {
        return {
          ...prevAd,
          [field]: { url: value },
        }
      }
      return {
        ...prevAd,
        [field]: value,
      }
    })
  }, [])

  const hiddenImageInput = React.useRef<HTMLInputElement>(null)

  const handleImageSelect = () => {
    hiddenImageInput?.current?.click()
  }

  const handleImageChange = (event: any) => {
    const fileUploaded = event.target.files[0]
    setSelectedImage(fileUploaded)
  }

  useEffect(() => {
    if (!selectedImage) return
    const objectUrl = URL.createObjectURL(selectedImage)
    updateAd('thumbnail', objectUrl)
  }, [selectedImage, updateAd])

  const hiddenLogoInput = React.useRef<HTMLInputElement>(null)

  const handleLogoSelect = () => {
    hiddenLogoInput?.current?.click()
  }

  const handleLogoChange = (event: any) => {
    const fileUploaded = event.target.files[0]
    setSelectedLogo(fileUploaded)
  }

  useEffect(() => {
    if (!selectedLogo) return
    const objectUrl = URL.createObjectURL(selectedLogo)
    return updateAd('companyLogo', objectUrl)
  }, [selectedLogo, updateAd])

  const inputComplete =
    ad.companyName &&
    ad.address &&
    ad.url &&
    (selectedImage || ad.thumbnail.url) &&
    (selectedLogo || ad.companyLogo.url)

  return (
    <Modal
      title="Ad."
      primaryDisabled={!inputComplete}
      onClose={props.onClose}
      bottomPrimaryLabel="Save"
      handleBottomPrimary={() => {
        inputComplete && props.onSave?.(ad, selectedImage, selectedLogo)
      }}
      bottomSecondaryLabel={update ? 'Delete' : undefined}
      handleBottomSecondary={() => setConfirmDelete(true)}
    >
      <InputContainer>
        <div>
          <S.P>Company name</S.P>
          <S.Margin vertical="8px" />
          <S.Input
            placeholder="Title"
            value={ad.companyName}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              updateAd('companyName', e.currentTarget.value)
            }}
          />
        </div>
        <div>
          <S.P>Location</S.P>
          <S.Margin vertical="8px" />
          <S.Input
            placeholder="Location"
            value={ad.address}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              updateAd('address', e.currentTarget.value)
            }}
          />
        </div>
        <div>
          <S.P>Url</S.P>
          <S.Margin vertical="8px" />
          <S.Input
            placeholder="Link"
            value={ad.url}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              updateAd('url', e.currentTarget.value)
            }}
          />
        </div>
        <div>
          <S.Flex>
            <MediaInputContainer>
              <S.P>Logo</S.P>
              <S.Margin vertical="8px" />
              <>
                <S.Button
                  fullWidth
                  label={
                    ad.companyLogo?.url || selectedLogo
                      ? 'Edit file'
                      : 'Upload file'
                  }
                  onClick={handleLogoSelect}
                  outlined
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={hiddenLogoInput}
                  onChange={handleLogoChange}
                  style={{ display: 'none' }}
                />
                <S.Margin vertical="4px" />
                <S.P>
                  {(selectedLogo
                    ? selectedLogo.name
                    : ad.companyLogo?.url
                    ? ad.companyLogo?.url
                    : 'No file selected'
                  ).slice(0, 45)}
                  {selectedLogo && selectedLogo.name.length > 45
                    ? '...'
                    : !selectedLogo && ad.companyLogo?.url.length > 45
                    ? '...'
                    : ''}
                </S.P>
              </>
            </MediaInputContainer>
            <S.Margin horizontal="8px" />
            <MediaInputContainer>
              <S.P>Image</S.P>
              <S.Margin vertical="8px" />
              <>
                <S.Button
                  fullWidth
                  label={
                    ad.thumbnail?.url || selectedImage
                      ? 'Edit file'
                      : 'Upload file'
                  }
                  onClick={handleImageSelect}
                  outlined
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={hiddenImageInput}
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                />

                <S.Margin vertical="4px" />
                <S.P>
                  {(selectedImage
                    ? selectedImage.name
                    : ad.thumbnail?.url
                    ? ad.thumbnail?.url
                    : 'No file selected'
                  ).slice(0, 45)}
                  {selectedImage && selectedImage.name.length > 45
                    ? '...'
                    : !selectedImage && ad.thumbnail?.url.length > 45
                    ? '...'
                    : ''}
                </S.P>
              </>
            </MediaInputContainer>
          </S.Flex>
        </div>
      </InputContainer>
      <AdCard
        ad={ad}
        onClick={() => {
          window.location.href = ad.url
        }}
      />
      {confirmDelete && (
        <DeleteConfirmation>
          <S.H3>Are you sure?</S.H3>
          <S.Margin vertical="8px" />
          <S.P>This action can not be undone.</S.P>
          <S.Margin vertical="8px" />
          <S.Flex>
            <S.Button outlined label="Cancel" />
            <S.Margin horizontal="8px" />
            <S.Button
              onClick={() => props.onDelete?.(ad.adId ?? '')}
              label="Delete"
            />
          </S.Flex>
        </DeleteConfirmation>
      )}
    </Modal>
  )
}

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
`

const MediaInputContainer = styled.div`
  width: 50%;
  height: 125px;
`

const DeleteConfirmation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffffe7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
