// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCw6Q0rYp0MtGsLXdNCu40XUxgCTaxUOSA',
  authDomain: 'l8er-4425d.firebaseapp.com',
  projectId: 'l8er-4425d',
  storageBucket: 'l8er-4425d.appspot.com',
  messagingSenderId: '35075962918',
  appId: '1:35075962918:web:a923a0fb3ca66cec8f8ee7',
  measurementId: 'G-82F266ESPB',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
