import { useState, useEffect } from 'react'
import { uploadRawImage } from '../api/api'

const useRawImageUpload = (initialImage?: File) => {
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    initialImage
  )
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  useEffect(() => {
    if (!selectedImage) return

    const fetchImageUrl = async () => {
      const image = await uploadRawImage(selectedImage)
      if (image) {
        setImageUrl(image.data.url)
      }
    }

    fetchImageUrl()
  }, [selectedImage])

  return { imageUrl, setSelectedImage }
}

export default useRawImageUpload
